import axios from "axios";
const apiUrl = process.env.NODE_ENV === "production" ? "https://goldfish-app-wd753.ondigitalocean.app/api/" : "http://localhost:8080/";

async function sendEvent(id, pid, event, url, user, description) {
    let body = {
        pid,
        type: event,
        url: url,
        data: description,
        user,
    };
    try {
        let response = await axios.post(apiUrl + "t/" + id, body);

        return response;
    } catch (e) {
        console.error("-- SOMETHING WENT WRONG!");
    }
}

export default sendEvent;
