import Fingerprint from "./modules/fingerprint";
import sendEvent from "./modules/api";

/* eslint-disable no-useless-escape */
let introLog = `
*****************************************************
     _    _               ______ _               
    | |  | |             |  ____| |              
    | |  | |___  ___ _ __| |__  | | _____      __
    | |  | / __|/ _ \ '__|  __| | |/ _ \ \ /\ / /
    | |__| \__ \  __/ |  | |    | | (_) \ V  V / 
     \____/|___/\___|_|  |_|    |_|\___/ \_/\_/  

                     WELCOME!
***************************************************** 
     `;

let { id, pid } = window._userflowSetupvars;
console.log(introLog);
const fingerprint = new Fingerprint({ canvas: false }).get();
console.log({ fingerprint,id, pid });
let url = window.location.href.split("?")[0];
_userFlowTrackEvent("pageview", { target: document.referrer });

document.addEventListener("click", (e) => _userFlowTrackEvent("click", e));
document.addEventListener("", (e) => _userFlowTrackEvent("click", e));

let inputs = document.getElementsByTagName("input");
inputs.push(...document.getElementsByTagName("textarea"));

inputs.forEach((i) => {
    i.addEventListener("change", (e) => _userFlowTrackEvent("change", e));
});

function _userFlowTrackEvent(ev, event) {
    console.log("event:", ev, event.target);


    let description = event.target.id || event.target.name || event.target.tagName || event.target || "";


    if(event.target.tagName === "BUTTON" || event.target.tagName === "A"){
        description = event.target.innerHTML;
    }

    sendEvent(id, pid, ev, url, fingerprint, description);
}
